import { useEffect } from 'react';
import { useNavigate } from 'react-router';

function XplorSpot() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/trader');
    }, []);

    return null;
}

export default XplorSpot;
