import { getPortalURL } from '@/helpers/environmentHelper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    selectCredentials,
    selectCurrentAccount,
    selectCurrentAccountName,
    setCurrentAccount,
    setCurrentAccountName
} from '@/state/reducers/authSlice';
import {
    openNotifications,
    selectNotificationsOpened,
    setNotificationsVisited
} from '@/state/reducers/notificationSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import { navigateAccordingToAccountType } from '@/utils/navigation';
import { Menu } from '@headlessui/react';
import cn from 'classnames';
import { ButtonHTMLAttributes, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
    MdExpandMore,
    MdOutlineAccountBalance,
    MdOutlineAccountBalanceWallet,
    MdOutlineAccountBox,
    MdOutlineAccountCircle,
    MdOutlineAdminPanelSettings,
    MdOutlineLogout,
    MdOutlineSettings
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { DropDown } from '../common/Dropdown';
import NotificationButton from '../common/NotificationButton';
import { onLogout, onUserChange } from './Navbar';

const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;
const portalURL = getPortalURL();

interface DesktopNavbarProps {
    depositDisclosure: UseDisclosureReturn;
    withdrawalDisclosure: UseDisclosureReturn;
    settingsDisclosure: UseDisclosureReturn;
}

const DesktopNavbar = ({ depositDisclosure, withdrawalDisclosure, settingsDisclosure }: DesktopNavbarProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const currentAccountName = useAppSelector(selectCurrentAccountName);
    const notificationsOpened = useAppSelector(selectNotificationsOpened);

    const navigate = useNavigate();

    const currentAccountType = useMemo(() => {
        const properties = credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [];
        return properties.find((property) => property.key === 'TYPE')?.value || 'NOP';
    }, [credentials, currentAccount]);

    return (
        <div className="hidden lg:flex flex-row items-center gap-3 py-2">
            <NotificationButton
                size="w-5 h-5"
                isActive={notificationsOpened}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    dispatch(openNotifications());
                }}
            />
            <DropDown
                label="Switch Accounts"
                button={<MenuButton label={currentAccountName} Icon={MdOutlineAccountBox} />}>
                {credentials?.accounts.map((account, i) => {
                    return (
                        <MenuItemButton
                            key={i}
                            label={account.name}
                            Icon={MdOutlineAccountBox}
                            onClick={() => {
                                dispatch(setCurrentAccount(account.code));
                                dispatch(setCurrentAccountName(account.name));
                                onUserChange(credentials, dispatch);
                                navigateAccordingToAccountType(account, navigate);
                            }}
                        />
                    );
                })}
            </DropDown>
            <DropDown label="User" button={<MenuButton label={credentials?.username} Icon={MdOutlineAccountCircle} />}>
                {portalURL && (
                    <MenuItemButton
                        label="AuricPortal"
                        Icon={MdOutlineAdminPanelSettings}
                        onClick={() => window.open(`https://${portalURL}`, '_blank')}
                    />
                )}
                {depositEnabled && (
                    <MenuItemButton
                        label="Deposits"
                        Icon={MdOutlineAccountBalanceWallet}
                        onClick={depositDisclosure[1].toggle}
                    />
                )}
                {withdrawalEnabled && (
                    <MenuItemButton
                        label="Withdrawals"
                        Icon={MdOutlineAccountBalance}
                        onClick={withdrawalDisclosure[1].toggle}
                    />
                )}
                <MenuItemButton label="Settings" Icon={MdOutlineSettings} onClick={settingsDisclosure[1].toggle} />
                <MenuItemButton
                    label="Log out"
                    Icon={MdOutlineLogout}
                    onClick={() => onLogout(credentials, dispatch)}
                />
            </DropDown>
        </div>
    );
};

export default DesktopNavbar;

interface MenuButtonProps {
    Icon: IconType;
    label?: string;
}

const MenuButton = (props: MenuButtonProps) => {
    const { Icon, label } = props;

    return (
        <Menu.Button className="flex w-56 gap-2 text-sm items-center px-2 p-1 justify-between text-neutral-200 bg-brand-background-dark rounded-md">
            <span>
                <Icon className="h-5 w-5" />
            </span>
            <div className="truncate whitespace-nowrap">{label}</div>
            <span className="ml-auto">
                <MdExpandMore className="h-5 w-5" />
            </span>
        </Menu.Button>
    );
};

interface MenuItemButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    Icon: IconType;
    label: string;
    disabledMessage?: string;
}

const MenuItemButton = (props: MenuItemButtonProps) => {
    const { Icon, label, disabledMessage, ...restProps } = props;
    const { disabled } = restProps;
    return (
        <Menu.Item>
            <button
                title={disabled ? disabledMessage : ''}
                className={cn('group flex w-full gap-2 items-center p-2 text-sm bg-brand-background-dark', {
                    'text-neutral-400 cursor-not-allowed': disabled,
                    'text-neutral-200 hover:bg-brand-primary': !disabled
                })}
                {...restProps}>
                <span>
                    <Icon className="h-5 w-5" />
                </span>
                <div className="truncate whitespace-nowrap">{label}</div>
            </button>
        </Menu.Item>
    );
};
