import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import {
    LoginResponse,
    LoginResponseType
} from './compiled_proto/com/celertech/baseserver/communication/login/DownstreamLoginProto';
import { fetchBuildRev } from './components/BuildRevision';
import { Button } from './components/common/Button';
import { LoginFormValues, loginSchema } from './components/form/schema/loginSchema';
import RHFInput from './components/inputs/RHFInput';
import RefreshVersionModal from './components/modal/RefreshVersionModal';
import TwoFactorAuthModal from './components/modal/TwoFactorAuthModal';
import buildJson from './config/build.json';
import { getPlatformLogo } from './helpers/environmentHelper';
import { useAppDispatch } from './state/hooks';
import { LoginStatus, controlClearSubscriptions, doLogin } from './state/reducers/authSlice';
import { useDisclosure } from './utils/hooks/useDisclosure';
import useToast from './utils/hooks/useToast';

interface ILoginFormValues extends LoginFormValues {
    server: unknown;
}

function Login() {
    const [toastError] = useToast();
    const refreshVersionDisclosure = useDisclosure(false);
    const twoFactorAuthDisclosure = useDisclosure(false);

    const [userRequires2FA, setUserRequires2FA] = useState<LoginResponse>();

    const form = useForm<ILoginFormValues>({
        defaultValues: {
            username: '',
            password: ''
        },
        mode: 'onChange',
        resolver: yupResolver(loginSchema(userRequires2FA))
    });
    const {
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isSubmitted, isSubmitting },
        register
    } = form;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = async (data: ILoginFormValues) => {
        const { username, password, twoFactorToken } = data;
        try {
            const resp = (await dispatch(doLogin({ username, password, twoFactorToken })).unwrap()) as LoginResponse;

            if (resp.loginResponseType === LoginResponseType.QR_TOKEN_CODE_REQUIRED) {
                // Requires 2FA
                setUserRequires2FA(resp);
                twoFactorAuthDisclosure[1].open();
            } else {
                // Assumes already logged in
                toast.dismiss();
                // Send user to celer screen

                // Force reload if build versions does not tally
                const latestRev = await fetchBuildRev();
                const buildRev = buildJson.build;
                if (buildRev !== latestRev) {
                    refreshVersionDisclosure[1].open();
                } else {
                    navigate('/trader');
                }
            }
        } catch (e) {
            const err = e as { code: LoginStatus; message?: string };

            if (err.code === LoginStatus.BAD_CREDENTIALS) {
                if (twoFactorToken) {
                    // If twoFactorToken is provided in this authentication attempt, then
                    const msg = 'Invalid verification code. Please try again.';
                    toastError({ body: msg, title: 'Login Failure', persist: true });
                    cancelTwoFactorAuth();
                } else {
                    const msg = 'Invalid credentials. Please try again.';
                    setError('server', { type: 'custom', message: msg });
                    toastError({ body: msg, title: 'Login Failure' });
                }
            } else if (err.code === LoginStatus.UNEXPECTED) {
                const msg = 'Unexpected Error';
                setError('server', { type: 'custom', message: msg });
                toastError({ body: msg, title: 'Login Failure' });
            }
        }
    };

    const cancelTwoFactorAuth = () => {
        setValue('twoFactorToken', undefined);
        setUserRequires2FA(undefined);
        twoFactorAuthDisclosure[1].close();
    };

    useEffect(() => {
        dispatch(controlClearSubscriptions());
    }, []);

    return (
        <div className="h-full bg-brand-background flex flex-col justify-center py-12 px-6">
            <div className="mx-auto w-full max-w-md">
                <img className="mx-auto h-20 w-auto" src={getPlatformLogo()} alt="GCEX" />
            </div>

            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-8 mx-auto w-full max-w-md">
                        <div className="p-6 sm:p-8 rounded-md sm:rounded-lg sm:px-10 bg-brand-background-dark border-black shadow-[0_20px_76px_33px_rgba(0,0,0,0.66)]">
                            {errors['server'] && isSubmitted ? (
                                <span className="text-brand-red italic">{errors['server'].message}</span>
                            ) : null}
                            <div className="space-y-6">
                                <RHFInput
                                    {...register('username')}
                                    type="username"
                                    label="Username"
                                    autoComplete="username"
                                />
                                <RHFInput
                                    {...register('password')}
                                    type="password"
                                    label="Password"
                                    autoComplete="current-password"
                                />
                                <div>
                                    <Button isLoading={isSubmitting}>Sign in</Button>
                                </div>
                            </div>
                            <div
                                className="mt-6 relative flex justify-center text-sm font-medium text-neutral-300 hover:text-neutral-200 cursor-pointer"
                                onClick={() => navigate('/forgot-password')}>
                                Forgot your password?
                            </div>
                        </div>
                    </div>
                    <RefreshVersionModal opened={refreshVersionDisclosure[0]} handlers={refreshVersionDisclosure[1]} />
                    <TwoFactorAuthModal
                        opened={twoFactorAuthDisclosure[0]}
                        handlers={twoFactorAuthDisclosure[1]}
                        userRequires2FA={userRequires2FA}
                        manualOnSubmit={handleSubmit(onSubmit)}
                        cancelTwoFactorAuth={cancelTwoFactorAuth}
                    />
                </form>
            </FormProvider>
        </div>
    );
}

export default Login;
