export const traderManifest = (environment) => ({
    short_name: 'Auric International Markets',
    name: 'Auric International Markets',
    icons: [
        {
            src: 'manifest-auric.jpg',
            sizes: '512x512 192x192 64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
            purpose: 'any maskable'
        }
    ],
    start_url: '.',
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff'
});
