export type CSSVariableNames =
    | '--color-brand-primary'
    | '--color-brand-primary-light'
    | '--color-brand-primary-dark'
    | '--color-brand-primary-darker'
    | '--color-brand-red'
    | '--color-brand-red-light'
    | '--color-brand-red-dark'
    | '--color-brand-red-darker'
    | '--color-chart-brand-primary'
    | '--color-chart-brand-primary-light'
    | '--color-chart-brand-primary-dark'
    | '--color-chart-brand-primary-darker'
    | '--color-chart-brand-red'
    | '--color-chart-brand-red-light'
    | '--color-chart-brand-red-dark'
    | '--color-chart-brand-red-darker'
    | '--color-brand-background'
    | '--color-brand-background-light'
    | '--color-brand-background-dark'
    | '--color-brand-neutral'
    | '--color-brand-neutral-light'
    | '--color-brand-neutral-dark'
    | '--color-brand-neutral-darker'
    | '--color-brand-neutral-darker-2'
    | '--color-brand-neutral-darkest'
    | '--font-sans'
    | '--font-secondary';

export type CSSVariables = {
    [key in CSSVariableNames]: string;
};
// https://tailwindcss.com/docs/customizing-colors

const useCSSVariables = () => {
    const computedStyle = getComputedStyle(document.documentElement);

    // create an array of variable names
    const variableNames: CSSVariableNames[] = [
        '--color-brand-primary',
        '--color-brand-primary-light',
        '--color-brand-primary-dark',
        '--color-brand-primary-darker',
        '--color-brand-red',
        '--color-brand-red-light',
        '--color-brand-red-dark',
        '--color-brand-red-darker',
        '--color-chart-brand-primary',
        '--color-chart-brand-primary-light',
        '--color-chart-brand-primary-dark',
        '--color-chart-brand-primary-darker',
        '--color-chart-brand-red',
        '--color-chart-brand-red-light',
        '--color-chart-brand-red-dark',
        '--color-chart-brand-red-darker',
        '--color-brand-background',
        '--color-brand-background-light',
        '--color-brand-background-dark',
        '--color-brand-neutral',
        '--color-brand-neutral-light',
        '--color-brand-neutral-dark',
        '--color-brand-neutral-darker',
        '--color-brand-neutral-darker-2',
        '--color-brand-neutral-darkest',
        '--font-sans',
        '--font-secondary'
    ];

    // create an empty object to store the variable values
    const variables = {} as CSSVariables;

    // loop through the variable names and add them to the object
    variableNames.forEach((name) => {
        const value = computedStyle.getPropertyValue(name).trim();
        variables[name] = value;
    });

    return variables;
};

export default useCSSVariables;
